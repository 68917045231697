import React from "react";
import { Box, Typography, Container, Button, Link } from "@mui/material";
import CountdownTimer from "../../components/countdownTimer";
import BrandLogos from "../../components/brandLogos";
import backgroundImage from "../../assets/images/comingSoonBg.png";
import companyLogo from "../../assets/images/BMLogo.png";
import spoonImage from "../../assets/images/Spoon.png";
import BarSection from "../../components/barSection";
import { useNavigate } from "react-router-dom";

const ComingSoon = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundColor: "#121212",
        color: "#f1f1f1",
        minHeight: "100vh",
        width: "100vw",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {/* Logo in top-left corner */}
      <Box
        component="img"
        src={companyLogo}
        alt="Company Logo"
        sx={{
          position: "absolute",
          top: { xs: 16, sm: 32 },
          left: { xs: 16, sm: 50 },
          width: { xs: "120px", sm: "200px", md: "264px" },
          height: "auto",
        }}
      />

      {/* Container with maxWidth disabled to allow full width */}
      <Container maxWidth={false} sx={{ padding: 0 }}>
        <Box
          sx={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            color: "#fff",
            height: { xs: "50vh", sm: "70vh" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
          }}
        >
          {/* Coming Soon Text and Countdown Timer */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "100%",
              padding: "20px 0",
              marginTop: { xs: "60px", sm: "120px" },
            }}
          >
            {/* Coming Soon Text */}
            <Typography
              sx={{
                fontFamily: "Cormorant Upright",
                fontSize: { xs: "32px", sm: "60px", md: "70px" },
                fontWeight: 500,
                lineHeight: { xs: "40px", sm: "80px", md: "100px" },
                letterSpacing: "0.04em",
                textAlign: "center",
                color: "#DCCA87",
              }}
            >
              Coming Soon
            </Typography>

            {/* Decorative Divider */}
            <Box
              component="img"
              src={spoonImage}
              alt="Decorative Spoon Icon"
              sx={{
                marginTop: "10px",
                marginBottom: "10px",
                width: { xs: "30px", sm: "40px" },
                height: "auto",
              }}
            />

            {/* Description Text */}
            <Typography
              sx={{
                fontFamily: "Open Sans",
                fontSize: { xs: "12px", sm: "16px" },
                fontWeight: 400,
                lineHeight: "28px",
                letterSpacing: "0.02em",
                color: "#FFFFFF",
                maxWidth: "650px",
                textAlign: "center",
                paddingX: { xs: "20px", sm: "0px" },
              }}
            >
              BottleMaps revolutionizes the drinking experience with a virtual
              personalized bar system and cashless payment platform, allowing
              customers to enjoy their drinks at any partner venue.
            </Typography>
            <CountdownTimer />
          </Box>
        </Box>

        {/* Supporting Brands */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
            padding: "20px 0",
          }}
        >
          <Box
            component="img"
            src={spoonImage}
            alt="Decorative Spoon Icon"
            sx={{
              marginTop: "10px",
              width: "40px",
            }}
          />

          <Typography
            sx={{
              fontFamily: "Cormorant Upright",
              fontSize: { xs: "30px", sm: "40px", md: "44px" },
              fontWeight: 500,
              letterSpacing: "0.04em",
              textAlign: "center",
              color: "#DCCA87",
            }}
          >
            Supporting Brands
          </Typography>
          <BrandLogos />
        </Box>
        <BarSection />
      </Container>

      {/* Footer */}
      <Box
        sx={{
          backgroundColor: "#000",
          color: "#f1f1f1",
          textAlign: "center",
          padding: "15px 0",
          marginTop: "40px",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography variant="body2">
            {/* © {new Date().getFullYear()} BottleMaps. All rights reserved. */}
          </Typography>
          <Box>
            <Link
              href="/contact-us"
              sx={{
                color: "#DCCA87",
                textDecoration: "none",
                marginRight: "15px",
                fontSize: "14px",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              Contact Us
            </Link>
            <Link
              href="/privacy"
              sx={{
                color: "#DCCA87",
                textDecoration: "none",
                fontSize: "14px",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              Privacy Policy
            </Link>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default ComingSoon;
