import React, { useState, useEffect } from "react";
import { Box, Typography, Grid2 } from "@mui/material";

const CountdownTimer = () => {
  const calculateTimeLeft = () => {
    const launchDate = new Date("2025-03-15T00:00:00").getTime();
    const now = new Date().getTime();
    const difference = launchDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        months: Math.floor(difference / (1000 * 60 * 60 * 24 * 30)),
        days: Math.floor((difference / (1000 * 60 * 60 * 24)) % 30),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    // Use setInterval for repeated updates
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(timer);
  }, []);

  return (
    <Box mb={12}>
      <Grid2 container spacing={2} justifyContent="center">
        {timeLeft.months !== undefined ? (
          <>
            {/* Months */}
            <Grid2 item xs={6} sm={4} md={2}>
              <Box textAlign="center">
                <Typography
                  sx={{
                    fontFamily: "Cormorant Upright",
                    fontSize: { xs: "24px", sm: "36px", md: "44px" },
                    color: "#F5EFDB",
                  }}
                >
                  {timeLeft.months}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Cormorant Upright",
                    color: "#AAAAAA",
                  }}
                >
                  Months
                </Typography>
              </Box>
            </Grid2>

            {/* Days */}
            <Grid2 item xs={6} sm={4} md={2}>
              <Box textAlign="center">
                <Typography
                  sx={{
                    fontFamily: "Cormorant Upright",
                    fontSize: { xs: "24px", sm: "36px", md: "44px" },
                    color: "#F5EFDB",
                  }}
                >
                  {timeLeft.days}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Cormorant Upright",
                    color: "#AAAAAA",
                  }}
                >
                  Days
                </Typography>
              </Box>
            </Grid2>

            {/* Hours */}
            <Grid2 item xs={6} sm={4} md={2}>
              <Box textAlign="center">
                <Typography
                  sx={{
                    fontFamily: "Cormorant Upright",
                    fontSize: { xs: "24px", sm: "36px", md: "44px" },
                    color: "#F5EFDB",
                  }}
                >
                  {timeLeft.hours}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Cormorant Upright",
                    color: "#AAAAAA",
                  }}
                >
                  Hours
                </Typography>
              </Box>
            </Grid2>

            {/* Minutes */}
            <Grid2 item xs={6} sm={4} md={2}>
              <Box textAlign="center">
                <Typography
                  sx={{
                    fontFamily: "Cormorant Upright",
                    fontSize: { xs: "24px", sm: "36px", md: "44px" },
                    color: "#F5EFDB",
                  }}
                >
                  {timeLeft.minutes}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Cormorant Upright",
                    color: "#AAAAAA",
                  }}
                >
                  Minutes
                </Typography>
              </Box>
            </Grid2>

            {/* Seconds */}
            <Grid2 item xs={6} sm={4} md={2}>
              <Box textAlign="center">
                <Typography
                  sx={{
                    fontFamily: "Cormorant Upright",
                    fontSize: { xs: "24px", sm: "36px", md: "44px" },
                    color: "#F5EFDB",
                  }}
                >
                  {timeLeft.seconds}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Cormorant Upright",
                    color: "#AAAAAA",
                  }}
                >
                  Seconds
                </Typography>
              </Box>
            </Grid2>
          </>
        ) : (
          <Grid2 item xs={12}>
            <Typography variant="h4" color="#FFFFFF">
              Launch Time Reached!
            </Typography>
          </Grid2>
        )}
      </Grid2>
    </Box>
  );
};

export default CountdownTimer;
