import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Container,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    mail: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [responseType, setResponseType] = useState();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResponseMessage("");

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      mail: formData.mail,
      message: formData.message,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://y4ep24r5ke.execute-api.ap-southeast-1.amazonaws.com/prod/api/contact-us",
        requestOptions
      );
      const result = await response.text();
      setResponseMessage("Message sent successfully!");
      setResponseType("success");
      setFormData({
        mail: "",
        message: "",
      });
    } catch (error) {
      setResponseMessage("Error sending message. Please try again.");
      setResponseType("error");

      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#121212",
        color: "#f1f1f1",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // justifyContent: "center",
        justifyContent: "space-between",
        // padding: "40px 0",
      }}
    >
      <Container maxWidth="sm" sx={{ textAlign: "center", paddingTop: "40px" }}>
        <Typography
          variant="h4"
          sx={{
            fontFamily: "Cormorant Upright",
            fontSize: { xs: "32px", sm: "44px" },
            fontWeight: 500,
            letterSpacing: "0.04em",
            color: "#DCCA87",
            marginBottom: "10px",
          }}
        >
          Contact Us
        </Typography>

        <Typography
          sx={{
            fontSize: "16px",
            color: "#f1f1f1",
            marginBottom: "20px",
          }}
        >
          Have questions? Reach out to us!
        </Typography>

        {/* Contact Form */}
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#1A1A1A",
            padding: "25px",
            borderRadius: "8px",
            boxShadow: "0 0 15px rgba(255, 255, 255, 0.1)",
          }}
        >
          <TextField
            label="Email Address"
            type="email"
            name="mail"
            value={formData.mail}
            onChange={handleChange}
            required
            fullWidth
            sx={{
              marginBottom: "15px",
              input: { color: "#fff", backgroundColor: "transparent" },
              label: { color: "#DCCA87" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#DCCA87" },
                "&:hover fieldset": { borderColor: "#DCCA87" },
                "&.Mui-focused fieldset": { borderColor: "#DCCA87" },
                "& input:-webkit-autofill": {
                  WebkitBoxShadow: "0 0 0px 1000px #1A1A1A inset",
                  WebkitTextFillColor: "#fff",
                },
              },
            }}
          />

          <TextField
            label="Message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            multiline
            rows={4}
            fullWidth
            sx={{
              marginBottom: "15px",
              textarea: { color: "#fff" },
              label: { color: "#DCCA87" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#DCCA87" },
                "&:hover fieldset": { borderColor: "#DCCA87" },
                "&.Mui-focused fieldset": { borderColor: "#DCCA87" },
              },
            }}
          />

          <Button
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#DCCA87",
              color: "#121212",
              fontSize: "16px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#b3946c" },
              padding: "10px",
            }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: "#121212" }} />
            ) : (
              "Send Message"
            )}
          </Button>
        </Box>

        {responseMessage && (
          <Typography
            sx={{
              marginTop: "15px",
              color: responseType === "success" ? "#ffffff" : "#d9534f",
            }}
          >
            {responseMessage}
          </Typography>
        )}

        <Button
          variant="text"
          sx={{ marginTop: "15px", color: "#DCCA87", fontSize: "14px" }}
          onClick={() => navigate("/")}
        >
          Back to Home
        </Button>
      </Container>

      {/* Footer */}
      <Box
        sx={{
          backgroundColor: "#000",
          color: "#f1f1f1",
          textAlign: "center",
          padding: "15px 0",
          width: "100%",
          marginTop: "40px",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography variant="body2">
            {/* © {new Date().getFullYear()} BottleMaps. All rights reserved. */}
          </Typography>
          <Box>
            <Button
              href="/contact-us"
              sx={{
                color: "#DCCA87",
                textDecoration: "none",
                marginRight: "15px",
                fontSize: "14px",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              Contact Us
            </Button>
            <Button
              href="/privacy"
              sx={{
                color: "#DCCA87",
                textDecoration: "none",
                fontSize: "14px",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              Privacy Policy
            </Button>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default ContactUs;
