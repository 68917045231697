import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ComingSoon from "./pages/comingSoon/comingSoon";
import AgeVerification from "./components/ageVerificationComponent";
import PrivacyPolicy from "./pages/privacy/index";
import ContactUs from "./pages/contactUs";

function App() {
  return (
    <Router>
      <Routes>
        {/* Define the route for the ComingSoon component */}
        <Route path="/" element={<AgeVerification />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />

        {/* You can add more routes here for other pages */}
      </Routes>
    </Router>
  );
}

export default App;
